<template>
  <div>
    <div class="shop-header" v-if="relStore.id=='0'">
      <div class="shop-left">
<!--        <span class="text">小程序模版呈现：</span>-->
<!--        <div class="option-item">-->
<!--          <span class="option-item_text">总部首页</span>-->
<!--          <el-switch v-model="index_type" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="changeShop('platform')"></el-switch>-->
<!--        </div>-->
<!--        <div class="option-item">-->
<!--          <span class="option-item_text">店铺首页</span>-->
<!--          <el-switch v-model="index_type" :active-value="2" :inactive-value="1" active-color="#13ce66" @change="changeShop('shop')"></el-switch>-->
<!--        </div>-->
<!--        <el-select v-model="page_type" clearable @change="selectPageType" placeholder="全部">
          <el-option
            v-for="item in page_type_selects"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>-->
        <span class="text">模版同步设置：</span>
        <div class="option-item">
          <span class="option-item_text">同步并启用</span>
          <el-switch v-model="is_enforce_sync" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="toggleSynchronizeShop"></el-switch>
        </div>
      </div>
      <div class="section-white mini-setting" v-if="relStore.id=='0'">
        <el-button type="text" @click="handleShowConfig" style="margin-right: 10px;">
          <i class="iconfont icon-cog" style="color: #8080FF;"></i> 小程序配置
        </el-button>
        <el-button type="text" @click="handleShowTabConfig">
          <i class="iconfont icon-cog" style="color: #8080FF;"></i> 小程序导航配置
        </el-button>
      </div>
    </div>

    <el-tabs type="border-card" v-model="params.platform" @tab-click="handleClick" >
      <el-tab-pane label="微信小程序" name="wxapp"></el-tab-pane>
      <el-tab-pane label="抖音小程序" name="byte_mini"></el-tab-pane>
    <el-row :gutter="20" class="template-list" :class="{'is-shop': relStore.id!='0'}" v-loading="listLoading">
      <el-col v-for="(item, index) in templateList" :key="index" :xs="10" :sm="10" :md="8" :lg="6" :xl="4">
        <div class="template-item">
          <div class="img-wrap">
            <div class="preview-cover" @click="handlePreview(item)">
              <img class="preview-cover_img" src="/static/img/preview.png" alt="预览">
              <span class="preview-cover_text">预览</span>
            </div>
            <img class="template-pic" :src="item.template_pic" />
            <div class="tag" v-if="item.template_type==1">同步模板</div>
          </div>
          <div class="template-name">
            <span style="font-weight: bold; font-size: 16px">{{item.template_title}}</span>
            <el-tag effect="dark" v-if="item.page_type == 'default' && !distributorId" style="float: right">总部</el-tag>
            <el-tag effect="dark" type="warning" v-if="item.page_type == 'distributor'" style="float: right">店铺</el-tag>
            <el-tag effect="dark" type="danger" v-if="item.page_type == 'hot'" style="float: right">热卖</el-tag>
          </div>
          <div class="template-common" v-if="relStore.id=='0'">
            <span class="temp-label">店铺可编辑挂件</span>
            <el-switch v-if="item.page_type == 'distributor'" v-model="item.element_edit_status" @change="changeShopEdit(index)" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2"></el-switch>
            <el-switch v-else v-model="item.element_edit_status" @change="changeShopEdit(index)" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2" disabled></el-switch>
          </div>
          <div class="template-common">
            <span class="temp-label">立即启用</span>
            <span class="temp-label" v-if="item.examine_time_format">({{item.examine_time_format}})</span>

            <el-tooltip class="item" effect="dark" content="至少开启一套模版" placement="top-start" v-if="item.page_type != 'distributor'">
              <el-switch :disabled="(item.examine_status==4) ? false : true" v-model="item.status" @change="useTemplate(index, item.status)" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2"></el-switch>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="开启后，新增店铺将默认启用该模版" placement="top-start" v-else>
              <el-switch :disabled="(item.examine_status==4) ? false : true" v-model="item.status" @change="useTemplate(index, item.status)" active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="2"></el-switch>
            </el-tooltip>

          </div>
          <div class="time-wrap">
            <div class="no-time" v-if="!item.showTime">
              <div>定时启用</div>
              <div class="picker-wrap">
                <img class="time-img" src="/static/img/time-img.png">
                <span>设置模板切换时间</span>
                <el-date-picker :disabled="(item.examine_status==4) ? false : true" v-model="item.timer_time" @change="changeDate(index)" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"></el-date-picker>
              </div>
            </div>
            <div class="has-time" v-if="item.showTime">
              <span class="time">{{item.timer_time}}启用</span>
              <span class="cancel-btn" @click="cancelTime(index)">取消</span>
            </div>
          </div>
          <div class="option-btns">
            <span v-if="login_type=='disti'" class="btn" @click="editTemplate(item)">编辑</span>
            <span v-if="login_type=='disti'" class="btn" @click="handleCopyTemplate(item.pages_template_id)">复制</span>
            <span v-if="login_type=='disti'" class="btn" @click="abandonTemplate(item.pages_template_id)">废弃</span>

            <span v-if="item.examine_status == 1 && login_type === 'disti'" class="btn" @click="submitExamine(item)">提交审核</span>
            <span v-if="item.examine_status == 2 && login_type === 'disti'" class="btn" @click="handleExamineLog(item)">审核中</span>
            <span v-if="item.examine_status == 3 && login_type === 'disti'" class="btn" @click="handleExamineLog(item)">审核未通过</span>
            <span v-if="item.examine_status == 4 && login_type === 'disti'" class="btn" @click="handleExamineLog(item)">审核已通过</span>

            <span v-if="item.examine_status == 1 && (login_type === 'admin' || login_type === 'staff')" class="btn" @click="handleExamineLog(item)">待提交</span>
            <span v-if="item.examine_status == 2 && (login_type === 'admin' || login_type === 'staff')" class="btn" @click="handleExamineLog(item)">待审核</span>
            <span v-if="item.examine_status == 3 && (login_type === 'admin' || login_type === 'staff')" class="btn" @click="handleExamineLog(item)">审核未通过</span>
            <span v-if="item.examine_status == 4 && (login_type === 'admin' || login_type === 'staff')" class="btn" @click="handleExamineLog(item)">审核已通过</span>
          </div>
          <div class="synchronize-btn" v-if="login_type!='disti' || (item.page_type != 'distributor' && relStore.id=='0')" style="color: #d2d2d2">同步模板至店铺</div>
<!--          <div class="synchronize-btn" v-else-if="relStore.id=='0'" @click="synchronizeTemplateToShop(index)">同步模板至店铺</div>-->
          <div class="synchronize-btn" v-else-if="relStore.id=='0'" @click="showTemplateDistributor(item.pages_template_id)">同步模板至店铺</div>
        </div>
      </el-col>
      <el-col v-if="relStore.id == 0 && login_type=='disti'" :xs="10" :sm="10" :md="8" :lg="6" :xl="4">
        <div class="template-item add-btn" @click="openDialog">
          <div class="template-wrap">
            <img class="add-img" src="/static/img/add-template.png" alt="添加">
            <div class="add-text">添加模板</div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-pagination
      style="text-align: center; margin-bottom: 20px"
      background
      @current-change="currentChange"
      :current-page="params.page_no"
      :page-size="params.page_size"
      layout="total, prev, pager, next"
      :total="total_count"
    ></el-pagination>
    </el-tabs>

    <DistributorSelect :platform="params.platform" :store-visible="distributorVisible" :is-synchronize="isSynchronize" :distributor-valid="'true,false'" :show-status="true" :rel-data-ids="relDistributors" :get-status="distributorStatus" @chooseStore="distributorChooseAction" @chooseAllStore="allDistributorChooseAction" @closeStoreDialog="closeDialogAction"></DistributorSelect>
    <imgPicker :dialog-visible="imgsVisible" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeimgsVisible"></imgPicker>
    <MallDecoration :dialog-visible="templateVisible" :template-name="template_name" :rel-store="relStore" :show-like="is_open_recommend" :template-id="currTemplateId" @saved="closeDialog" @closeDialog="closeDialog" />

    <MallDecorationDouyin :dialog-visible="templateVisibleDouyin" :template-name="template_name" :rel-store="relStore" :show-like="is_open_recommend" :template-id="currTemplateId" @saved="closeDialogDouyin" @closeDialog="closeDialogDouyin" />
<!--    <TemplatePreview :dialog-visible="previewVisible" :rel-store="relStore" :show-like="is_open_recommend" :template-id="currTemplateId" :tabs="tabs" @saved="closePreviewDialog" usage="page" @closeDialog="closePreviewDialog" />-->

    <ExamineDistributorTemplate v-if="selectTemplate.page_type === 'distributor'"  @closeDialog="closeExamineDialog" :examineDialogVisible="examineDialogVisible" :templateId="selectTemplate.pages_template_id" templateType="index"></ExamineDistributorTemplate>
    <ExamineTemplate v-else @closeDialog="closeExamineDialog" :examineDialogVisible="examineDialogVisible" :templateId="selectTemplate.pages_template_id" templateType="index"></ExamineTemplate>

    <TemplateDistributorList
      :platform="params.platform"
      :store-visible="templateDistributorVisible"
      :template-id="currTemplateId"
      :distributor-valid="'true,false,delete'"
      :refresh-data="refreshDistributorSelect"
      @handleClose="closeTemplateDistributorDialogAction"
      @handleCreateClick="handleSynchronizeTemplateToShop"
    >

    </TemplateDistributorList>

    <el-dialog
      title="预览模版"
      :visible.sync="previewVisible"
      :before-close="closePreviewDialog"
      width="30%">
      <div style="text-align: center" v-loading="imgLoading">
        <div>
          请使用手机扫码预览
        </div>
        <div class="block">
          <el-image
            style="width: 200px; height: 200px"
            :src="previewImgSrc">
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="previewVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <el-dialog title="新增模板" :visible.sync="dialogVisible" :close-on-click-modal="false" @closed="closeAddDialog" width="50%">
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="模版名称" prop="template_title">
          <el-input v-model="form.template_title" maxlength="10" show-word-limit style="width: 55%;"></el-input>
        </el-form-item>
        <el-form-item label="使用平台">
          <el-select v-model="form.platform" clearable disabled placeholder="微信小程序">
            <el-option label="微信小程序" value="wxapp"></el-option>
            <el-option label="抖音小程序" value="byte_mini"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板封面">
          <div class="setting-item slider" style="width: 55%;">
            <img v-if="form.template_pic" :src="form.template_pic" class="banner-uploader" @click="handleImgChange" />
            <div class="banner-uploader" v-else @click="handleImgChange">
              <i class="iconfont icon-camera"></i>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="模版类型" prop="template_title">
          <el-radio-group v-model="form.page_type" size="small">
            <!-- <el-radio-button label="default">总部模版</el-radio-button> -->
            <el-radio-button label="distributor">店铺模版</el-radio-button>
<!--            <el-radio-button label="hot">热卖页模版</el-radio-button>-->
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer content-center">
        <el-button type="primary" @click="addTemplate('form')">确 定</el-button>
      </div>
    </el-dialog>

    <sideBar :visible.sync="show_sideBar" :title="'小程序配置'" width="20">
      <el-form label-width="120px">
        <el-form-item label="开启猜你喜欢">
          <el-switch v-model="is_open_recommend" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="toggleOpenRecommend"></el-switch>
        </el-form-item>
        <el-form-item label="开启小程序定位">
          <el-switch v-model="is_open_wechatapp_location" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="toggleOpenWechatappLocation"></el-switch>
        </el-form-item>
        <el-form-item label="开启扫码功能">
          <el-switch v-model="is_open_scan_qrcode" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="toggleOpenScanQrcode"></el-switch>
        </el-form-item>
        <el-form-item label="开启公众号组件">
          <el-switch v-model="is_open_official_account" :active-value="1" :inactive-value="2" active-color="#13ce66" @change="toggleOpenOfficialAccount"></el-switch>
        </el-form-item>
      </el-form>
    </sideBar>

    <sideBar :visible.sync="show_tab_sideBar" :title="'小程序导航配置'" width="35" >
      <tabsBarOne  v-model="editorData" ref="tabsbarsetting"/>
      <div slot="footer">
        <el-button type="primary" @click="handelSaveTab" class="save-btn">保存</el-button>
      </div>
    </sideBar>
    <!-- <sideBar :visible.sync="show_tab_sideBar" :title="'小程序导航配置'" width="45">
      <div class="template-tabs" :style="{ background: tabs.config.backgroundColor, color: tabs.config.color }">
        <div class="current-active"></div>
        <div class="tab" v-for="(item, index) in tabs.data" :key="index" :style="index === currentTab ? `color:${tabs.config.selectedColor}` : ''">
          <svg v-if="!item.iconPath" class="svg-icon" aria-hidden="true" :style="index === currentTab ? `color:${tabs.config.selectedColor}` : ''">
            <use :xlink:href="`#icon-${item.name}`"></use>
          </svg>
          <template v-else>
            <img v-if="index === currentTab" class="svg-icon" :src="item.selectedIconPath || 'https://fakeimg.pl/60x60/EFEFEF/CCC/?text=icofont=lobster'" />
            <img v-else class="svg-icon" :src="item.iconPath || 'https://fakeimg.pl/60x60/EFEFEF/CCC/?text=icon&font=lobster'" />
          </template>
          <div class="tab-text">{{item.text}}</div>
        </div>
      </div>
      <tabsEditor :res="editorData" @bindImgs="showImgs" @saveTab="handelSaveTab" @bindLinks="showLinks"></tabsEditor>
    </sideBar> -->

    <el-dialog title="复制模版" :visible.sync="copyVisible" width="30%">
      <el-form :model="copyForm" ref="copyForm" label-width="80px">
        <el-form-item label="模版名称">
          <el-input v-model="copyForm.template_title" class="input-b"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer content-center">
        <el-button type="primary" @click="closeCopyDialog">取消</el-button>
        <el-button type="primary" @click="copyTemplate">确定</el-button>
      </div>
    </el-dialog>

    <linkSetter :visible="linksVisible" @setLink="setLink" @closeDialog="closeDialog" :template_name="template_name"></linkSetter>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ExamineTemplate from "@/components/function/examineTemplate"
import ExamineDistributorTemplate from "@/components/function/examineDistributorTemplate"
import DistributorSelect from "@/components/function/distributorSelect";
import ShopDecoration from "@/components/function/shopDecoration";
import MallDecoration from "@/components/function/mallDecoration";
import MallDecorationDouyin from '@/components/function/MallDecorationDouyin'
import TemplatePreview from "@/components/function/templatePreview";
import imgPicker from "@/components/imageselect";
import sideBar from "@/components/element/sideBar";
import tabsEditor from "@/components/template_editor/tab_bar_new";
import linkSetter from '@/components/template_links'

import {
  setPagesTemplate,
  getPagesTemplateSetInfo,
  getPagesTemplateList,
  addPagesTemplate,
  copyPagesTemplate,
  deletePagesTemplate,
  getPagesTemplateDetail,
  savePagesTemplate,
  syncPagesTemplate,
  modifyPagesTemplateStatus,
  submitExamineTemplate,
  getTemplatePreviewQrCode,
  getDistributorPagesTemplateList,
  getDistributorExamineList,
  examineDistributorTemplate,
} from "@/api/template";
import TemplateDistributorList from "./templateDistributorList";
import tabsBarOne from "@/components/template_editor/tab_bar_one";
import { cloneDeep } from 'lodash'

export default {
  props: {
    relStore: {
      type: Object,
      default: () => {
        return {};
      }
    },
    templateType: {
      type: String,
      default: () => {
        return 'default';
      }
    }
  },
  components: {
    TemplateDistributorList,
    DistributorSelect,
    imgPicker,
    ShopDecoration,
    MallDecoration,
    MallDecorationDouyin,
    TemplatePreview,
    sideBar,
    tabsEditor,
    linkSetter,
    ExamineTemplate,
    ExamineDistributorTemplate,
    tabsBarOne
  },
  data() {
    return {
      copyVisible: false,
      copyForm: {
        pages_template_id: 0,
        template_title: '',
      },
      imgLoading: false,
      previewImgSrc: '',
      selectTemplate: {},
      examineDialogVisible: false,
      examineVisible: false,
      examineForm: {
        examine_result: 2,
        remark: '',
        log_id: 0,
        template_id: 0,
      },
      btnLoading: false,
      examineTableLoading: false,
      logData: [],
      log_total_count: 0,
      pageParams: {
        page: 1,
        pageSize: 20
      },
      templateDistributorVisible: false,
      distributorId: 0,
      trueValue: true,
      index_type: 1,
      orgin_index_type: "platform",
      is_enforce_sync: 2,
      is_open_recommend: 2,
      is_open_wechatapp_location: 2,
      is_open_scan_qrcode: 2,
      is_open_official_account: 2,
      templateList: [],
      form: {
        template_title: "",
        template_pic: "",
        page_type: "distributor",//"default", // default 总部模版    hot 热卖页
        platform: ''
      },
      rules: {
        template_title: [
          { required: true, message: "请输入模板名称", trigger: "blur" }
        ]
      },
      distributorVisible: false,
      isValid: true,
      relDistributors: [],
      distributorStatus: false,
      dialogVisible: false,
      imgsVisible: false,
      isGetImage: false,
      isSynchronize: false,
      templateVisible: false,
      templateVisibleDouyin: false,
      refreshDistributorSelect: '',
      previewVisible: false,
      currTemplateId: null,
      params: {
        page_no: 1,
        page_size: 10,
        platform: 'wxapp',
        page_type: this.templateType,
      },
      total_count: 0,
      show_sideBar: false,
      show_tab_sideBar: false,
      tabIcon: "",
      currentTab: 0,
      selectedTab: false,
      tabs: {
        name: "tabs",
        config: {
          color: "#333333",
          selectedColor: "#1f82e0",
          backgroundColor: "#ffffff"
        },
        data: [
          {
            pagePath: "/pages/index",
            text: "首页",
            name: "home",
            iconPath: "",
            selectedIconPath: ""
          },
          {
            pagePath: "/pages/category/index",
            text: "分类",
            name: "category",
            iconPath: "",
            selectedIconPath: ""
          },
          {
            pagePath: "/pages/cart/espier-index",
            text: "购物车",
            name: "cart",
            iconPath: "",
            selectedIconPath: ""
          },
          {
            pagePath: "/pages/member/index",
            text: "我的",
            name: "member",
            iconPath: "",
            selectedIconPath: ""
          }
        ]
      },
      editorData: {},
      editorDataIndex: null,
      // 路径设置组件
      linksVisible: false,
      page_type_selects: [
        {
          value: 'default',
          label: '总部模版',
        },
        // {
        //   value: 'hot',
        //   label: '热卖页模版',
        // },
        // {
        //   value: 'distributor',
        //   label: '店铺模版',
        // },
      ],
      page_type: '',
      listLoading: false,
    };
  },
  mounted() {
    this.getTemplateSetInfo();
    this.getList();
    this.distributorId = this.relStore.distributor_id
  },
  computed: {
    ...mapGetters(["template_name", "login_type"])
  },
  methods: {
    handleClick (tab, event) {
      this.params.page_no = 1
      this.getList()
    },
    currentChange(page) {
      this.params.page_no = page
      this.getList()
    },

    submitExamine(row) {
      this.listLoading = true;
      this.$confirm('确认提交审核?审核期间将不能编辑该模版', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {template_id: row.pages_template_id}
        submitExamineTemplate(params).then(response => {
          if (response.data.data.success == true) {
            this.$message.success('提交成功')
          } else {
            this.$message.error('提交失败')
          }
          this.listLoading = false;
          this.getList();
        }).catch(error => {
          this.listLoading = false;
          this.$message.error('提交失败')
          this.getList();
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
        this.listLoading = false;
        this.getList();
      });
    },

    handleExamineLog(item) {
      this.selectTemplate = item
      this.examineDialogVisible = true;
    },

    getTemplateSetInfo() {
      getPagesTemplateSetInfo().then(res => {
        let data = res.data.data;
        this.index_type = data.index_type;
        this.is_enforce_sync = data.is_enforce_sync;
        this.is_open_recommend = data.is_open_recommend;
        this.is_open_wechatapp_location = data.is_open_wechatapp_location;
        this.is_open_scan_qrcode = data.is_open_scan_qrcode;
        this.is_open_official_account = data.is_open_official_account;
        if (this.index_type == 1) {
          this.orgin_index_type = "platform";
        } else {
          this.orgin_index_type = "shop";
        }
        if (data.tab_bar) {
          this.tabs = JSON.parse(data.tab_bar);
        }
      });
    },
    getList(status) {
      this.listLoading = true;
      let params = {
        ...this.params,
        distributor_id: this.relStore.id,
        // page_type: this.page_type,
      };
      getDistributorPagesTemplateList(params).then(res => {
        // this.te·mplateList = res.data.data.list
        let list = [];
        res.data.data.list.map(item => {
          list.push({
            ...item,
            element_edit_status: item.element_edit_status || 2,
            status: item.status || 2,
            timer_status: item.timer_status || 2,
            showTime: false,
            timer_time: !item.timer_time
              ? ""
              : this.formatTimeStampToStr(item.timer_time),

            // examine_time_format: this.formatTimeStampToStr(ite.template_status_modify_time),
            showTime: item.timer_status == 1
          });
        });
        this.templateList = list;
        this.total_count = res.data.data.total_count;
        this.listLoading = false;
      }).catch(error => {
        this.listLoading = false;
      });
    },
    formatTimeStampToStr(timeStamp) {
      //时间戳转时间字符串
      var date = new Date();
      date.setTime(timeStamp * 1000);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    handleImgChange(data) {
      this.imgsVisible = true;
      this.isGetImage = true;
    },
    pickImg(data) {
      if (this.editorDataIndex != null) {
        if (this.tabIcon === "default") {
          this.editorData.data[this.editorDataIndex].iconPath = data.url;
          this.tabs.data[this.editorDataIndex].iconPath = data.url;
        } else {
          this.editorData.data[this.editorDataIndex].selectedIconPath =
            data.url;
          this.tabs.data[this.editorDataIndex].selectedIconPath = data.url;
        }
      } else {
        this.form.template_pic = data.url;
      }
      this.imgsVisible = false;
    },
    closeimgsVisible() {
      this.imgsVisible = false;
    },
    distributorChooseAction(data) {
      if (data === null || data.length <= 0) {
        this.distributorVisible = false;
        this.distributorStatus = false;
        return;
      }
      this.relDistributors = data;
      let shop_ids = data.map(item => {
        return item.distributor_id;
      });
      this.syncTemplate(2, shop_ids);
    },
    allDistributorChooseAction() {
      this.syncTemplate(1);
    },
    syncTemplate(is_all_distributor, shop_ids) {
      let params = {
        pages_template_id: this.currTemplateId,
        is_all_distributor
      };
      if (is_all_distributor == 2) {
        params.distributor_ids = JSON.stringify(shop_ids);
      }
      syncPagesTemplate(params).then(res => {
        this.distributorVisible = false;
        this.distributorStatus = false;
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.refreshDistributorSelect = Math.random()
      });
    },
    closeDialogAction() {
      this.distributorStatus = false;
      this.distributorVisible = false;
      this.refreshDistributorSelect = Math.random()
    },
    changeShop(type) {
      if (type == "shop") {
        this.index_type = 2;
      } else {
        this.index_type = 1;
      }
      if (this.orgin_index_type == type) {
        return;
      } else {
        this.orgin_index_type = type;
      }
      setPagesTemplate({ index_type: this.index_type }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    toggleSynchronizeShop(val) {
      setPagesTemplate({ is_enforce_sync: val }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    toggleOpenRecommend(val) {
      setPagesTemplate({ is_open_recommend: val }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    toggleOpenWechatappLocation(val) {
      setPagesTemplate({ is_open_wechatapp_location: val }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    toggleOpenScanQrcode(val) {
      setPagesTemplate({ is_open_scan_qrcode: val }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    toggleOpenOfficialAccount(val) {
      setPagesTemplate({ is_open_official_account: val }).then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
      });
    },
    openDialog() {
      this.dialogVisible = true;
      this.editorDataIndex = null;
      this.form.platform = this.params.platform
    },
    closeAddDialog() {
      this.resetForm("form");
    },
    addTemplate(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            ...this.form,
            template_name: this.template_name,
            distributor_id: this.relStore.id
          };
          addPagesTemplate(params).then(res => {
            this.getList();
            this.resetForm(formName);
          });
        }
      });
    },
    resetForm(formName) {
      this.dialogVisible = false;
      this.$refs[formName].resetFields();
      this.form.template_pic = "";
    },
    previewTemplate(pages_template_id) {
      this.previewVisible = true;
      this.currTemplateId = pages_template_id;
    },
    async changeShopEdit(index) {
      let params = {
        distributor_id: this.relStore.id,
        pages_template_id: this.templateList[index].pages_template_id,
        template_content: this.templateList[index].template_content,
        element_edit_status: this.templateList[index].element_edit_status,
        template_name: this.template_name
      };
      await savePagesTemplate(params);
      this.$message({
        message: "操作成功",
        type: "success"
      });
    },
    useTemplate(index, val) {
      this.currTemplateId = this.templateList[index].pages_template_id;
      if (val == 1) {
        this.templateList[index].status = 2;
        this.$confirm("确认立即启用当前模板？", "启用模板", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            this.modifyTemplateStatus(index, "status", val);
          })
          .catch(() => {});
      } else {
        this.templateList[index].status = 1;
        this.modifyTemplateStatus(index, "status", val);
      }
    },
    useTimeTemplate(index, val) {
      this.currTemplateId = this.templateList[index].pages_template_id;
      if (val == 2) {
        this.templateList[index].timer_status = 1;
        this.$confirm("确认取消启用定时模板？", "取消定时模板", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        })
          .then(() => {
            this.modifyTemplateStatus(index, val);
            this.templateList[index].timer_time = "";
            this.templateList[index].showTime = false;
            this.templateList[index].timer_status = val;
          })
          .catch(() => {});
      } else {
        this.currTemplateId = this.templateList[index].pages_template_id;
        this.templateList[index].timer_status = val;
      }
    },
    cancelTime(index) {
      this.$confirm("确认取消启用定时模板？", "取消定时模板", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          this.currTemplateId = this.templateList[index].pages_template_id;
          this.modifyTemplateStatus(index, "timer_status", 2);
        })
        .catch(() => {});
    },
    changeDate(index) {
      this.currTemplateId = this.templateList[index].pages_template_id;
      this.modifyTemplateStatus(index, "timer_status", 1);
    },
    async modifyTemplateStatus(index, type, status) {
      let params = {
        pages_template_id: this.currTemplateId,
        template_name: this.template_name
      };
      if (type == "status") {
        params.status = status;
      } else {
        params.timer_status = status;
        // params.timer_time = this.templateList[index].timer_time
        params.timer_time =
          status == 1 ? this.templateList[index].timer_time : "";
      }
      await modifyPagesTemplateStatus(params);
      this.getList();
      this.$message({
        message: "操作成功",
        type: "success"
      });
    },
    editTemplate(obj) {
      const { pages_template_id, platform } = obj
      if ("byte_mini" === platform) {
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            template_douyin:1,
            template_id: pages_template_id,
            scene:1007,
          }
        })
      } else {
        // this.templateVisible = true;
        // pages_template_id
        // this.$router.push(`/wxapp/manage/decorate?template_id=${pages_template_id}`)
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            template_id: pages_template_id,
            scene:1003,
          }
        })
      }
      this.currTemplateId = pages_template_id;
    },
    closeCopyDialog () {
      this.copyVisible = false;
      this.copyForm = {
        pages_template_id: 0,
        template_title: '',
      }
    },
    handleCopyTemplate (pages_template_id) {
      this.copyForm = {
        pages_template_id: 0,
        template_title: '',
      }
      this.copyVisible = true
      this.copyForm.pages_template_id = pages_template_id
    },
    copyTemplate() {
      copyPagesTemplate(this.copyForm) .then(res => {
        this.$message({
          message: "操作成功",
          type: "success"
        });
        this.params.page_no = 1;
        this.getList();
        this.copyVisible = false;
      })
    },
    abandonTemplate(pages_template_id) {
      this.$confirm("确认废弃当前模板？", "废弃模板", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      })
        .then(() => {
          deletePagesTemplate(pages_template_id).then(res => {
            this.$message({
              message: "操作成功",
              type: "success"
            });
            this.params.page_no = 1;
            this.getList();
          });
        })
        .catch(() => {});
    },
    showTemplateDistributor (templateId) {
      this.currTemplateId = templateId
      this.templateDistributorVisible = true
      console.log(templateId)
    },
    closeTemplateDistributorDialogAction () {
      console.log('handleclose')
      this.templateDistributorVisible = false
    },
    handleSynchronizeTemplateToShop() {
      this.distributorVisible = true
      this.distributorStatus = true
      this.isSynchronize = true
      this.relDistributors = []
    },
    synchronizeTemplateToShop(index) {
      this.distributorVisible = true;
      this.distributorStatus = true;
      this.isSynchronize = true;
      this.relDistributors = [];
      this.currTemplateId = this.templateList[index].pages_template_id;
    },
    closeDialog() {
      this.templateVisible = false;
      this.getList()
    },
    closeDialogDouyin() {
      this.templateVisibleDouyin = false
      this.getList()
    },
    closePreviewDialog() {
      this.previewVisible = false;
    },
    handleShowConfig() {
      this.show_sideBar = true;
    },
    handleShowTabConfig() {
      this.show_tab_sideBar = true;
      this.editorData = cloneDeep(this.tabs);
    },
    handleCloseSidebar(status) {
      this.show_tab_sideBar = status;
    },
    showImgs(index, tabIcon) {
      this.imgsVisible = true;
      this.isGetImage = true;
      this.editorDataIndex = index;
      if (tabIcon) {
        this.tabIcon = tabIcon;
      }
    },
    showLinks (index) {
      this.linksVisible = true
      this.editorDataIndex = index
    },
    setLink (data, type) {
      if (this.editorData.name === 'tabs') {
        console.log(this.editorData, 776)
        this.setTabLink(data, type)
        return
      }
    },
    setTabLink (data, type) {
      console.log(data, type, this.editorData, 672)
      this.editorData.config.id = data.id
      this.editorData.config.title = data.title
      this.editorData.config.linkPage = type
      this.linksVisible = false
      /*if (this.editorData.base[0] === undefined) {
        this.editorData.base[0] = {}
      }
      if (this.tabs.base[0] === undefined) {
        this.editorData.base[0] = {}
      }
      this.editorData.base[0].id = data.id
      this.editorData.base[0].title = data.title
      this.editorData.base[0].linkPage = type
      this.tabs.base[0].id = data.id
      this.tabs.base[0].title = data.title
      this.tabs.base[0].linkPage = type
      this.linksVisible = false*/
    },
    handelSaveTab() {
      try {
        const v = this.$refs.tabsbarsetting.getInfo()
        let param = JSON.stringify(v);
        setPagesTemplate({ tab_bar: param }).then(res => {
          this.getTemplateSetInfo()
          this.$message({
            message: "操作成功",
            type: "success"
          });
        });
      } catch (error) {

      }

    },
    selectPageType() {
      this.getList()
    },

    closeExamineDialog () {
      this.examineDialogVisible = false
      this.getList();
    },

    handleCurrentChange (page_num) {
      this.pageParams.page = page_num
      this.getList()
    },

    handlePreview (row) {
      console.log(row)
      this.previewImgSrc = ''
      this.previewVisible = true
      this.imgLoading = true
      let pageType = 'index'
      if (row.page_type == 'default') {
        pageType = 'index'
      } else if (row.page_type == 'distributor') {
        pageType = 'distributor'
      }
      let params = {type: pageType, id:row.pages_template_id};
      getTemplatePreviewQrCode(params).then(response => {
        this.previewImgSrc = 'data:image/png;base64,' + response.data.data.qrcode
        this.imgLoading = false
      }).catch(error => {
        this.imgLoading = false
      })
    },

    // formatTimeStampToStr (timeStamp) {
    //   // 时间戳转时间字符串
    //   var date = new Date()
    //   date.setTime(timeStamp * 1000)
    //   var y = date.getFullYear()
    //   var m = date.getMonth() + 1
    //   m = m < 10 ? '0' + m : m
    //   var d = date.getDate()
    //   d = d < 10 ? '0' + d : d
    //   var h = date.getHours()
    //   h = h < 10 ? '0' + h : h
    //   var minute = date.getMinutes()
    //   var second = date.getSeconds()
    //   minute = minute < 10 ? '0' + minute : minute
    //   second = second < 10 ? '0' + second : second
    //   return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    // },
  }
};
</script>

<style scoped lang="scss">
.shop-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .shop-left {
    display: flex;
    align-items: center;
  }
  .text {
    padding-left: 20px;
    color: #ccc;
  }
}
.option-item {
  display: inline-block;
  margin-right: 20px;
  &_text {
    margin-right: 5px;
  }
}
.template-list {
  display: flex;
  flex-wrap: wrap;

  .template-item {
    border-radius: 10px;
    margin-bottom: 20px;
    border: 1px solid #eee;
    color: #606266;
    height: 442px;
    &.add-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f2f2f2;
      cursor: pointer;
      .add-img {
        width: 60px;
      }
      .add-text {
        font-size: 15px;
        color: #606266;
        margin-top: 10px;
      }
    }
    .img-wrap {
      width: 100%;
      height: 200px;
      background: #f2f2f2;
      position: relative;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      .template-pic {
        width: 100%;
      }
      .preview-cover {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        opacity: 0;
        background: rgba($color: #000000, $alpha: 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: all 1s ease;
        cursor: pointer;
        &_img {
          width: 40px;
        }
        &_text {
          font-size: 15px;
          color: #fff;
          margin-top: 5px;
        }
      }
      .tag {
        position: absolute;
        left: 0;
        top: 30px;
        z-index: 11;
        width: 36px;
        background: rgba(128, 128, 255, 0.39);
        font-size: 14px;
        color: #fff;
        text-align: center;
        padding: 6px 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &:hover {
        .preview-cover {
          z-index: 10;
          opacity: 1;
        }
      }
    }
    .template-name {
      font-size: 15px;
      margin: 15px;
    }
    .template-common {
      margin: 0 15px 15px;
      display: flex;
      justify-content: space-between;
      .temp-label {
        font-size: 13px;
      }
    }
    .time-wrap {
      height: 26px;
      margin: 0 15px 10px 15px;
      .no-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .picker-wrap {
        display: inline-block;
        font-size: 13px;
        color: #8080ff;
        position: relative;
        overflow: hidden;
        .time-img {
          width: 16px;
          vertical-align: top;
          margin-top: 1px;
        }
        .el-date-editor {
          position: absolute;
          z-index: 10;
          left: 0;
          opacity: 0;
        }
      }
      .has-time {
        display: flex;
        justify-content: space-between;
        font-size: 13px;
        .time {
          color: #d9001b;
        }
        .cancel-btn {
          color: #8080ff;
          cursor: pointer;
        }
      }
    }
    .option-btns {
      display: flex;
      border-top: 1px solid #eee;
      .btn {
        display: inline-block;
        padding: 10px 0;
        flex: 1;
        border-right: 1px solid #eee;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        &:last-child {
          border-right-width: 0;
        }
      }
    }
    .synchronize-btn {
      text-align: center;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
      border-top: 1px solid #eee;
      cursor: pointer;
    }
  }
  &.is-shop {
    .template-item {
      height: 367px;
    }
  }
}
.content-center {
  text-align: center;
}
.template-tabs {
  position: relative;
  display: flex;
  // width: 320px;
  width: 100%;
  height: 49px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  .tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 49px;
    text-align: center;
    .svg-icon {
      width: 24px;
      height: 24px;
      margin-bottom: 3px;
    }
    .tab-text {
      font-size: 11px;
      line-height: 1.2;
    }
  }
  .current-active {
    position: absolute;
    left: -3px;
    top: -3px;
    right: -3px;
    bottom: -3px;
    border: 3px solid #ff5000;
    z-index: -1;
    opacity: 0;
    box-shadow: 0 0 5px rgba(255, 80, 0, 0.3);
    background: rgba(255, 80, 0, 0.2);
    transition: all 0.3s ease;
  }
  &.active .current-active {
    opacity: 1;
    z-index: 999;
  }
}
</style>
<style lang="scss" scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606272;
}
.el-button + .el-button {
  margin-left: 10px !important;
}
</style>
