<style lang="scss">
.picker-goods {
  .sp-filter-form {
    padding: 8px 8px 0 8px;
  }

  .sp-finder-hd {
    display: none;
  }

  .sp-finder-hd {
    display: none;
  }

  .item-info {
    display: flex;

    .item-image {
      width: 60px;
      margin-right: 8px;
    }

    .sp-image {
      margin-right: 10px;
    }

    .item-name {
      color: #333;
      margin-top: 4px;
    }
  }

  .el-table {
    td {
      padding: 0;
    }
  }

  .disableheadselection {
    >.cell .el-checkbox__inner {
      display: none;
    }
  }

  .el-pagination {
    margin: 0;
    padding: 10px;
  }
}
</style>
<template>
  <div class="picker-goods">
    <SpFilterForm :model="formData" size="small" @onSearch="onSearch" @onReset="onSearch">
      <SpFilterFormItem v-if="isShowFormItem('distributor_id')" prop="distributor_id">
        <SpSelectShop v-model="formData.distributor_id" clearable placeholder="请选择店铺" @change="onSearch" />
      </SpFilterFormItem>
      <SpFilterFormItem prop="keywords">
        <el-input v-model="formData.keywords" clearable placeholder="请输入商品名称" />
      </SpFilterFormItem>
    </SpFilterForm>

    <SpFinder ref="finder" reserve-selection row-key="goods_id" :other-config="{
      'max-height': 460,
      'header-cell-class-name': cellClass
    }" url="/goods/items" :show-pager-text="`已选中：${localSelection.length}`" :fixed-row-action="true" :setting="{
      columns: [
        { name: '商品ID', key: 'goods_id', width: 80 },
        {
          name: '商品名称',
          key: 'page_name',
          render: (h, { row }) =>
            h('div', { class: 'item-info' }, [
              h('div', { class: 'item-image' }, [
                h('SpImage', {
                  props: {
                    src: row.pics[0],
                    width: 60,
                    height: 60
                  }
                })
              ]),
              h('div', { class: 'item-name' }, row.itemName)
            ])
        },
        { name: '商品货号', key: 'item_bn', width: 150 },
        {
          name: '价格（¥）',
          key: 'price',
          width: 100,
          render: (h, { row }) => h('span', {}, row.price / 100)
        },
        { name: '库存', key: 'store', width: 100 }
      ]
    }" :hooks="{
      beforeSearch: beforeSearch,
      afterSearch: afterSearch
    }" @select="onSelect" @select-all="handleSelectAll" />
  </div>
</template>

<script>
import { SALES_STATUS } from '@/consts'
import { cloneDeep } from 'lodash'
import BasePicker from './base'
import PageMixin from '../mixins/page'
import SpSelectShop from '@/components/sp-select-shop'
import { watch } from 'vue'

export default {
  name: 'PickerGoods',
  extends: BasePicker,
  mixins: [PageMixin],
  config: {
    title: '选择商品'
  },
  props: ['value'],
  components: { SpSelectShop },
  data() {
    const { queryParams } = this.value
    const defaultParams = {
      keywords: '',
      distributor_id: ''
    }
    const formData = Object.assign(defaultParams, queryParams)
    return {
      formData,
      salesStatus: SALES_STATUS,
      list: [],
      multipleSelection: [],
      goodsBranchList: [],
      goodsBranchParams: {
        page: 1,
        pageSize: 1000,
        attribute_type: 'brand',
        attribute_name: ''
      },
      categoryList: [],
      multiple: this.value?.multiple ?? true,
      localSelection: []
    }
  },
  created() {
    this.localSelection = cloneDeep(this.value.data) || []
  },
  mounted() {
    this.getGoodsBranchList()
    this.getCategory()
  },
  methods: {
    beforeSearch(params) {
      params = {
        ...params,
        item_type: 'normal',
        ...this.formData
      }
      return params
    },
    afterSearch(response) {
      const { list } = response.data.data
      if (this.localSelection.length > 0) {
        const ids = this.value.data.map((m) => m.goods_id)
        const selectRows = list.filter((item) => ids.includes(item.goods_id))
        const { finderTable } = this.$refs.finder.$refs
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selectRows)
        })
      }
    },
    onSearch() {
      this.$refs.finder.refresh(true)
    },
    onSelect(selection, row) {
      if (!this.multiple) {
        const { finderTable } = this.$refs.finder.$refs
        console.log('finderTable:', finderTable)
        finderTable.clearSelection()
        this.localSelection = [row]
        setTimeout(() => {
          finderTable.$refs.finderTable.setSelection(selection.length > 0 ? [row] : [])
        })
      } else {
        const isAdd = selection.includes(row)
        const idx = this.localSelection.findIndex((f) => f.goods_id === row.goods_id)
        if (isAdd && idx === -1) {
          this.localSelection.push(row)
        } else {
          this.localSelection.splice(idx, 1)
        }
      }
    },

    // onSelectionChange(selection) {
    //   this.localSelection = []
    //   this.updateVal(selection)
    // },
    handleSelectAll(list) {
      this.localSelection = [...list]
    },

    isShowFormItem(key) {
      const { paramsFieldExclude = [] } = this.value
      return !paramsFieldExclude.includes(key)
    },
    async getGoodsBranchList(searchVal = '') {
      this.goodsBranchParams.attribute_name = searchVal
      const { list } = await this.$api.goods.getGoodsAttr(this.goodsBranchParams)
      this.goodsBranchList = list
    },
    async getCategory() {
      const res = await this.$api.goods.getCategory({ is_show: false })
      this.categoryList = res
    },
  },
  watch: {
    localSelection: {
      handler(val) {
        this.updateVal(val)
      },
      deep: true
    }
  }
}
</script>
