<template>
  <div>
    <el-row v-if="goods.length > 0" :gutter="20">
      <el-col v-for="(item, index) in goods" :key="item.item_id" :xs="24" :sm="12" :md="8" :lg="6">
        <div class="goods">
          <div class="goods-thumbnail">
            <img :src="item.pics[0]" alt="">
          </div>
          <div class="goods-caption">
            <div class="goods-title">
              {{item.itemName}}
            </div>
          </div>
          <div v-if="canEdit"  class="goods-remove iconfont icon-trash-alt" @click="handleSkuRemove(index)"></div>
        </div>
      </el-col>
    </el-row>
    <el-button type="primary" @click="handleSelectGoods" :disabled="!canEdit">{{ btnName }}</el-button>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      default: []
    },
    // 回显id
    rowKey: {
      type: String,
      default: 'item_id'
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    btnName: {
      type: String,
      default: '选择商品'
    }
  },
  data () {
    return {
      goods: [],
    }
  },
  computed:{},
  components: {},
  watch: {
    data(val) {
      this.goods = val
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.goods)
    },
    handleSkuRemove(index) {
      this.goods.splice(index, 1)
      this.onChange()
    },
    async handleSelectGoods() {
      const ids = this.goods.map(item => item[this.rowKey])
      const { data } = await this.$picker.goods({
        data: ids
      })
      this.goods = data
      this.onChange()
    },
  }
}
</script>

<style lang="scss" scoped>
.goods {
  display: flex;
  position: relative;
  padding: 10px;
  height: 80px;
  margin-bottom: 10px;
  transition: all 0.5s ease;
  &:hover {
    background: #f5f7fa;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
  }
}
.goods-thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  background: #efefef;
  box-shadow: 0 0 2px rgba(0,0,0,.2);
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.goods-caption {
  flex: 1;
  padding-right: 30px;
}
.goods-title {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  box-orient: vertical;
  margin-bottom: 5px;
  width: 100%;
  max-height: 34px;
  font-size: 15px;
  line-height: 1.2;
  color: #333;
}
.goods-sku {
  color: #888;
}
.goods-sku-check {
  display: inline-block;
  margin-left: 5px;
  color: #1f82e0;
  cursor: pointer;
}
.goods-remove {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: #ff5000;
  }
}
.goods-badge {
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(227, 25, 20, 1);
  border-radius: 0px 0px 6px 0px;
  font-size: 10px;
  min-width: 19px;
  height: 17px;
  color: #fff;
  display: flex;
  padding: 0px 3px;
  align-items: center;
  justify-content: center;
}
</style>
